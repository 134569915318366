
import Store from '../../redux/store/store';
import { TokenExpired } from '../../redux/AuthSlice/AuthSlice';
import { sentryInfo } from '../../common/Utils/Sentry/Sentry';
import { LoginApi } from '../../constants/ApiConstants';
export const HTTPMethod = {
  Get: 'GET',
  Post: 'POST',
  Put: 'PUT',
  Delete: 'DELETE',
  Patch: 'PATCH'
};
export const ContentType = {
  Json: 1,
  FormData: 2
};
export const accessTokenObject = {
  getAccessToken:null,
}
export const callApi = async (method = HTTPMethod.Get, url = "", requestParameter = null, processName = "event-api-call", contentType = 1, isAnonymousCall = false) => {
  const isAccessTokenExpired =  checkTokenExpiration();
  if (isAccessTokenExpired || !localStorage.getItem("accessToken") || localStorage.getItem("accessToken") == "undefined") {
    Store.dispatch(TokenExpired());
    await accessTokenObject.getAccessToken;
  }
  let baseUrl = process.env.REACT_APP_BASE_API;
  let callUrl = baseUrl + url;
  const accessToken = localStorage.getItem("accessToken") 
   && localStorage.getItem("accessToken") !== "undefined" ? "Bearer " + localStorage.getItem("accessToken") : '';
  if(!accessToken && !isAnonymousCall){
    sentryInfo('event-exception',"error-access-token-null")
    return;
  }
  const impersonationToken = getImpersonationToken();
  const preHeaders = {
    "Authorization": accessToken,
    "Allow": "application/json;",
    "Content-type": "application/json",
    ...(impersonationToken && { "Impersonation": impersonationToken })
  };
  let requestBody = null;
  if (contentType === ContentType.FormData) {
    requestBody = requestParameter ? requestParameter : requestBody;
  } else {
    requestBody = requestParameter ? JSON.stringify(requestParameter) : requestBody;
  }
  let response;
  try {
     response = await fetch(`${callUrl}`, {
      method: method,
      headers: { ...preHeaders },
      body: requestBody,
    })
    if (response) {
      if (response.status == 401) {
        sentryInfo(processName, "fail", { statusCode: response.status });
        sessionStorage.setItem("TokenExpired", true);
        Store.dispatch(TokenExpired());
        return;
      } else if (response.status == 403) {
        sentryInfo(processName, "fail", { statusCode: response.status })
        if(url == LoginApi.userConfig){
          return {status_code: response.status};
        }
        window.location.href = "/forbidden";
        return;
      }
      else if (response.status == 404 || response.status == 400) {
        sentryInfo(processName, "fail", { statusCode: response.status })
        window.location.href = "/not-found";
        return;
      }
      else if (response.status == 406) {
        sentryInfo('event-impersonation', "stopped", { statusCode: response.status })
        localStorage.removeItem('Impersonation');
        window.location.href = process.env.REACT_APP_PRO_URL + "CwpStopImpersonation"; 
        return;
      } else if(response.status == 200) {
        const data = await response.json();
        if (data){
          if(processName !== "event-api-call") {
            sentryInfo(processName, "success", { statusCode: response?.status })
          }
          return data;
        }     
      } else {
        sentryInfo(processName, "fail", { statusCode: response.status })
        return {status_code: response.status};
      }
    }
  } catch (err) {
    console.error(err);
    sentryInfo(processName, "fail", { statusCode: response?.status, exception: err })
  }
}
export const exportCallApi = async (exportCallApiMethod = HTTPMethod.Get, url="", requestParams = null, contentType = 1) => {
  const baseExportCallAPiUrl = process.env.REACT_APP_BASE_API;
  const callExportCallApiUrl = baseExportCallAPiUrl + url;
  const accessToken = localStorage.getItem("accessToken") ? "Bearer " + localStorage.getItem("accessToken") : '';
  const impersonationToken = getImpersonationToken();
  const preCallApiHeaders = {
    "Authorization": accessToken,
    "Allow": "application/json;",
    "Content-type": "application/json",
    ...(impersonationToken && { "Impersonation": impersonationToken })
  };
  let exportCallApiRequestBody = null;

  if (contentType === ContentType.FormData) {
    exportCallApiRequestBody = requestParams ? requestParams : exportCallApiRequestBody;
  } else {
    exportCallApiRequestBody = requestParams ? JSON.stringify(requestParams) : exportCallApiRequestBody;
  }
  try {
    const request = await fetch(`${callExportCallApiUrl}`, {
      method: exportCallApiMethod,
      headers: { ...preCallApiHeaders },
      body: exportCallApiRequestBody,
    });

    if (request && request.status == 401) {
        Store.dispatch(TokenExpired());
        return;
    } else {
      if (request)
        return request;
    }
  } catch (err) {
    console.error(err);
  }
};

export const checkTokenExpiration = () => {
  const localStorageAuth0Key = `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::${process.env.REACT_APP_AUTH0_AUDIENCE}::openid profile email offline_access`;
  const tokenData = JSON.parse(localStorage.getItem(localStorageAuth0Key));
  const isTokenExpired = sessionStorage.getItem("TokenExpired");
  if (isTokenExpired) {
    return true;
  }
  if (tokenData?.expiresAt) {
    const expireDate = new Date(tokenData.expiresAt * 1000);
    const currentDate = new Date();
    return expireDate - currentDate <= 200000;
  }
  return false; 
};

const getImpersonationToken = () => {
  const impersonationToken = localStorage.getItem("Impersonation");
  if(impersonationToken) 
    return impersonationToken;
}