import dayjs from "dayjs";
import { OrdersTypeEnum, WalkThroughPathType } from "../../enums";
import { LineOfBusinessEnum } from "../../../constants/BusinessConstants";
import { commonConstants, regExPattern } from "../../constants";
import { callApi, HTTPMethod } from "../../../services/HttpService/HttpService";
import { LoginApi } from "../../../constants/ApiConstants";

export const handlePrimaryContact = (contactData) => {
    const tempPatientData = [];
      const primaryContact = contactData?.find(contact => contact.is_primary_contact);
      if (primaryContact) 
      tempPatientData[0] = primaryContact;

      contactData?.forEach(contact => {
        if (!contact.is_primary_contact) {
            tempPatientData.push(contact);
        }
      });
      return tempPatientData;
}

export const capitalizeFirstLetter = (str) =>  str?.charAt(0).toUpperCase() + str?.slice(1);

export const mixedOrderTitle = (mixOrderConfig) => {
  const orders = mixOrderConfig.split(",");
  let ordersTooltip = orders.map(order => OrdersTypeEnum[order]);

  if (ordersTooltip.length === 2) {
    return `Proof of ${ordersTooltip.join(" and ")}`;
  } else if (ordersTooltip.length > 2) {
    const lastOrder = ordersTooltip.pop();
    return `Proof of ${ordersTooltip.join(", ")} and ${lastOrder}`;
  }

  return `Proof of ${ordersTooltip.join(", ")}`;
}

export const checkFirstLetterValid = (val) => {
  return !(/^\s/.test(val));
};

export const thirtyDaysBackDate = () => {
  const priorDate = dayjs().add(-30, 'day');
  return dayjs(priorDate).format('MM/DD/YYYY')
}


const patientsToMembers = LineOfBusinessEnum.patientsToMembers.toLowerCase();
const patientToMember = LineOfBusinessEnum.patientToMember.toLowerCase();

export const checkUrlPath = () => {
  const pathname = location.pathname;
  if (pathname === `/${patientsToMembers}` || pathname === '/members' || pathname === '/residents') {
      return WalkThroughPathType.Patients;
  } else if (pathname === '/orders') {
      return WalkThroughPathType.Orders;
  } else if (pathname === '/orders/new-order') {
      return WalkThroughPathType.NewOrder;
  } else if (pathname.includes(`/${patientsToMembers}/${patientToMember}-details`) || pathname.includes(`/members/member-details/`) || pathname.includes(`residents/resident-details`)) {
      return `PatientDetailsTab`;
  } else if (pathname.includes('/orders/order-details')) {
      return WalkThroughPathType.OrdersDetailsTab;
  } else {
      return '';
  }
};

export const formatReportDate = (dateValue) => {
  return dateValue.format('MMM YYYY')
}

export const formatAdvanceOrderReportDate = (dateValue) => {
  return dayjs(dateValue).format('MM/DD/YYYY')
}

export const formattedDate = new Date().toLocaleString('en-US', {
  timeZone: 'America/Denver', // MST timezone
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
}).replace(',', ' ');

export const convertDateAndTime = (dateString) => {
  if (!dateString || dateString == commonConstants.defaultDateAndTimeValue) {
    return null;
  }

  const [datePart, timePart] = dateString.split("T");
  const [year, month, day] = datePart.split("-").map(Number);
  const [hours, minutes] = timePart.split(":").map(Number);

  return `${month}/${day}/${year} ${hours}:${minutes}`;
};

export const reportFormattedDate = (dateString) => {
  if(dateString == commonConstants.defaultDateAndTimeValue) return "-";

  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

export const formatPrescriptionDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatPhoneNumber = (number) => {
  return number.replace(regExPattern?.phoneNumberWithHypen, regExPattern?.phoneNumberFormat);
};
export const formatHeight = (inches) => {
  inches = inches.toString();
  if (inches.length == 3) {
      return `${inches[0]}'${inches[1]}${inches[2]}"`;
  }
  if (inches.length == 2) {
      return `${inches[0]}'${inches[1]}"`;
  }
  if (inches.length == 1) {
      return `${inches[0]}'`;
  }
}
export const handleStringToBoolean = (value) => value !== "" ? value == "true" : null;
export const getValueOrEmptyString = (value) => (value !== null && value !== undefined ? value + "" : "");

export const preAuthSetValues = (fieldsMapping, setValue) => {
  fieldsMapping.forEach(({ key, value }) => setValue(key, value));
};

export const logoutUser = async (userId) => {
  if (userId) {
    const requestBody = {
      user_id: userId
    };
    await callApi(HTTPMethod.Post, LoginApi.logout, requestBody);
  }
}