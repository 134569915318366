
import React from "react";
import { Typography, FormControl, Button, FormLabel, FormControlLabel, Radio, RadioGroup, Grid, Dialog, DialogActions, TextField, DialogContent, DialogTitle, Box } from "@mui/material";
import styles from '../../PreAuthForms/PreAuthForms/FormAuth.module.css';
import { useTranslation } from "react-i18next";


const PortableOxygenConcentrator = ({ handleClose, preAuthFormData }) => {

    
    const { t } = useTranslation();
    return (
        <>
            {
                preAuthFormData && (

                    <Dialog fullWidth maxWidth="md" open={true} onClose={handleClose} >
                        <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}>
                            {t("PreAuthForm.portableOxygenConcentratorFormTitle")}
                        </DialogTitle>

                        <DialogContent>
                            <Typography sx={{ margin: '10px 0px' }}>
                                <span className={styles.asterisk}>*</span> {t("PreAuthForm.requiredField")}
                            </Typography>

                            {
                                preAuthFormData[0]?.BranchCode !== undefined && preAuthFormData[0].BranchCode !== '' &&
                                <Grid container>
                                    <Grid xs={12} md={6} >
                                        <FormControl className={styles.spacingRemoveTop}>
                                            <div>
                                                <Typography sx={{ margin: '10px 0px' }} className={styles.labelText}>
                                                {t("PreAuthForm.branchCode")} <span className={styles.asterisk}> *</span>
                                                </Typography>
                                            </div>
                                            <div >
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    size="small"
                                                    autoFocus
                                                    variant="outlined"
                                                    id="name"
                                                    type="text"
                                                    value={preAuthFormData[0].BranchCode}
                                                    sx={{ marginTop: '5px', backgroundColor: 'var(--color-disableField)',
                                                        '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, borderRadius:"8px"}}
                                                    disabled={true}
                                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                                />
                                            </div>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            }

                            {
                                preAuthFormData[0]?.DiagnosisSupportsPortableOxygen !== undefined && preAuthFormData[0]?.DiagnosisSupportsPortableOxygen !== '' &&
                                <Grid container>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <div>
                                            <Typography className={styles.labelText}>
                                                1. {t("PreAuthForm.DiagnosisSupportsPortableOxygen")} <span className={styles.asterisk}> *</span>
                                            </Typography>
                                        </div>
                                        <TextField
                                            fullWidth
                                            aria-label="minimum height"
                                            rows={3}
                                            size="small"
                                            value={preAuthFormData[0].DiagnosisSupportsPortableOxygen}
                                            sx={{
                                                marginTop: '5px',
                                                resize: 'verical',
                                                overflow: 'auto',
                                                maxHeight: '300px',
                                                backgroundColor: 'var(--color-disableField)',
                                                '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                                                borderRadius:"8px"
                                            }}
                                            disabled={true}
                                            inputProps={{ style: { cursor: 'not-allowed' } }}
                                        />
                                    </Grid>
                                </Grid>
                            }

                            {
                                preAuthFormData[0]?.HasPortableOxygenTransferHospice !== undefined && preAuthFormData[0].HasPortableOxygenTransferHospice !== '' &&
                                <Grid container>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <FormControl className={styles.spacingRemoveTop}>
                                            <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">
                                                2. {t("PreAuthForm.HasPortableOxygenTransferHospice")}<span className={styles.asterisk}> *</span></FormLabel>
                                            <RadioGroup
                                                value={preAuthFormData[0].HasPortableOxygenTransferHospice}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                className='paddingLeftFifteenpx'
                                            >
                                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            }

                            {
                                preAuthFormData[0]?.VendorName !== undefined && preAuthFormData[0]?.VendorName !== '' &&
                                <Grid container sx={{ paddingLeft: '10px' }}>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <div>
                                            <Typography className={styles.labelText}>
                                                a. {t("PreAuthForm.vendorName")}
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                aria-label="minimum height"
                                                minRows={2}
                                                size="small"
                                                value={preAuthFormData[0].VendorName}
                                                sx={{ marginTop: '5px', backgroundColor: 'var(--color-disableField)',
                                                    '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    },
                                                    borderRadius:"8px" 
                                                 }}
                                                disabled={true}
                                                inputProps={{ style: { cursor: 'not-allowed' } }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            }

                            {
                                preAuthFormData[0]?.VendorPhoneNumber !== undefined && preAuthFormData[0]?.VendorPhoneNumber !== '' &&
                                <Grid container sx={{ paddingLeft: '10px' }}>
                                    <Grid xs={12} className={styles.spacingTop} >
                                        <div>
                                            <Typography className={styles.labelText}>
                                                b. {t("PreAuthForm.vendorPhone")}
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                aria-label="minimum height"
                                                minRows={2}
                                                size="small"
                                                value={preAuthFormData[0].VendorPhoneNumber}
                                                sx={{
                                                    marginTop: '5px', cursor: 'not-allowed', backgroundColor: 'var(--color-disableField)',
                                                    '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                                                    borderRadius:"8px"
                                                }}
                                                disabled={true}
                                                inputProps={{
                                                    style: {
                                                        cursor: 'not-allowed'
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            }


                            {
                                preAuthFormData[0]?.ClinicalRationalePortableOxygen !== undefined && preAuthFormData[0]?.ClinicalRationalePortableOxygen !== '' &&
                                <Grid container sx={{ paddingLeft: '10px' }}>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <div>
                                            <Typography className={styles.labelText}>
                                                c. {t("PreAuthForm.ClinicalRationalePortableOxygen")} <span className={styles.asterisk}> *</span>
                                            </Typography>
                                        </div>
                                        <TextField
                                            fullWidth
                                            aria-label="minimum height"
                                            rows={3}
                                            size="small"
                                            value={preAuthFormData[0].ClinicalRationalePortableOxygen}
                                            sx={{
                                                marginTop: '5px', cursor: 'not-allowed', backgroundColor: 'var(--color-disableField)',
                                                '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                                                borderRadius:"8px"
                                            }}
                                            disabled={true}
                                            inputProps={{
                                                style: {
                                                    cursor: 'not-allowed'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            }


                            {
                                preAuthFormData[0]?.IsPhysicianOrder !== undefined && preAuthFormData[0]?.IsPhysicianOrder !== '' &&
                                <Grid container sx={{ paddingLeft: '10px' }}>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <FormControl className={styles.spacingRemoveTop}>
                                            <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">
                                                d. {t("PreAuthForm.physicianOrder")} <span className={styles.asterisk}> *</span>
                                            </FormLabel>
                                            <Box component="span" sx={{ fontWeight: 'bold', fontStyle: "italic", marginLeft: '18px', marginTop:'4px' }}>
                                                {t('PreAuthForm.mdOrder')}
                                            </Box>
                                            <RadioGroup value={preAuthFormData[0].IsPhysicianOrder} row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" className='paddingLeftFifteenpx'>
                                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} />
                                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            }



                            {
                                preAuthFormData[0]?.IsHighFlowOxygenNeeds !== undefined && preAuthFormData[0]?.IsHighFlowOxygenNeeds !== '' &&
                                <Grid container>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <FormControl className={styles.spacingRemoveTop}>
                                            <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 
                                                3. {t("PreAuthForm.portableOxygenConcentratorHighFlowOxygenNeeds")} <span className={styles.asterisk}> *</span></FormLabel>
                                            <RadioGroup
                                                value={preAuthFormData[0].IsHighFlowOxygenNeeds}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                className='paddingLeftFifteenpx'
                                            >
                                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} />
                                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            }


                            {
                                preAuthFormData[0]?.CurrentLiterFlowOrdered !== undefined && preAuthFormData[0]?.CurrentLiterFlowOrdered !== '' &&
                                <Grid container>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <div>
                                            <Typography className={styles.labelText}>
                                                4. {t("PreAuthForm.currentLiterFlow")}
                                            </Typography>
                                        </div>
                                        <TextField
                                            minRows={2}
                                            aria-label="requesting custom text"
                                            size="small"
                                            fullWidth
                                            value={preAuthFormData[0].CurrentLiterFlowOrdered}
                                            sx={{
                                                marginTop: '5px', cursor: 'not-allowed', backgroundColor: 'var(--color-disableField)',
                                                '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                                                borderRadius:"8px"
                                            }}
                                            disabled={true}
                                            inputProps={{
                                                style: {
                                                    cursor: 'not-allowed'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            }


                            {
                                preAuthFormData[0]?.IsPatientAmbulatory !== undefined && preAuthFormData[0]?.IsPatientAmbulatory !== '' &&
                                <Grid container>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <FormControl className={styles.spacingRemoveTop}>
                                            <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">
                                                5. {t("PreAuthForm.IsThePatientAmbulatory")} <span className={styles.asterisk}> *</span>
                                            </FormLabel>
                                            <RadioGroup
                                                value={preAuthFormData[0].IsPatientAmbulatory}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                className='paddingLeftFifteenpx'
                                            >
                                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} />
                                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            }

                            {
                                preAuthFormData[0]?.Pps !== undefined && preAuthFormData[0]?.Pps !== '' &&
                                <Grid container>
                                    <Grid xs={12} className={styles.spacingTop} sx={{ paddingLeft: '20px' }}>
                                        <div>
                                            <Typography className={styles.labelText}>
                                                a. {t("PreAuthForm.pps")} <span className={styles.asterisk}> *</span>
                                            </Typography>
                                        </div>
                                        <TextField
                                            aria-label="minimum height"
                                            rows={3}
                                            size="small"
                                            value={preAuthFormData[0].Pps}
                                            sx={{
                                                marginTop: '5px', cursor: 'not-allowed', backgroundColor: 'var(--color-disableField)',
                                                '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                                                borderRadius:"8px"
                                            }}
                                            disabled={true}
                                            inputProps={{
                                                style: {
                                                    cursor: 'not-allowed'
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid xs={12} className={styles.spacingTop} sx={{ paddingLeft: '20px' }}>
                                        <div>
                                            <Typography className={styles.labelText}>
                                                b. {t("PreAuthForm.nyhaClass")} <span className={styles.asterisk}> *</span>
                                            </Typography>
                                        </div>
                                        <TextField
                                            aria-label="minimum height"
                                            rows={3}
                                            size="small"
                                            value={preAuthFormData[0].NyhaClass}
                                            sx={{
                                                marginTop: '5px', cursor: 'not-allowed', backgroundColor: 'var(--color-disableField)',
                                                '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                                                borderRadius:"8px"
                                            }}
                                            disabled={true}
                                            inputProps={{
                                                style: {
                                                    cursor: 'not-allowed'
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>
                            }


                            {
                                preAuthFormData[0]?.IsDisablingDyspnea !== undefined && preAuthFormData[0]?.IsDisablingDyspnea !== '' &&
                                <Grid container>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <FormControl className={styles.spacingRemoveTop}>
                                            <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">
                                                6. {t("PreAuthForm.IsDisablingDyspnea")} <span className={styles.asterisk}> *</span>
                                            </FormLabel>
                                            <RadioGroup
                                                value={preAuthFormData[0].IsDisablingDyspnea}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                className='paddingLeftFifteenpx'
                                            >
                                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} />
                                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} />

                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            }


                            {
                                preAuthFormData[0]?.IsM6Tried !== undefined && preAuthFormData[0]?.IsM6Tried !== '' &&
                                <Grid container>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <FormControl className={styles.spacingRemoveTop}>
                                            <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">
                                                7. {t("PreAuthForm.IsM6Tried")}<span className={styles.asterisk}> *</span>
                                            </FormLabel>
                                            <RadioGroup
                                                value={preAuthFormData[0].IsM6Tried}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                className='paddingLeftFifteenpx'
                                            >
                                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} />
                                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} />

                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                        <FormLabel className={`${styles.labelText} displayFlex paddingLeft20px`} id="demo-row-radio-buttons-group-label">
                                            <div>
                                                <span style={{ fontWeight: 'bold' }}>{t("SupportComponent.note")}&nbsp;</span>{t("PreAuthForm.preAuthNote")}
                                            </div>
                                        </FormLabel>
                                </Grid>}


                            {
                                preAuthFormData[0]?.OtherOxygenConcentrator !== undefined && preAuthFormData[0]?.OtherOxygenConcentrator !== '' &&
                                <Grid container>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label">
                                            <div>8.&nbsp;</div>
                                            <div>{t("PreAuthForm.OtherOxygenConcentrator")}<span className="asteriskSignColor"> *</span>
                                            </div>
                                        </FormLabel>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={3}
                                            size="small"
                                            value={preAuthFormData[0].OtherOxygenConcentrator}
                                            aria-label="minimum height"
                                            sx={{
                                                marginTop: '5px', cursor: 'not-allowed', backgroundColor: 'var(--color-disableField)',
                                                '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                                                borderRadius:"8px"
                                            }}
                                            disabled={true}
                                            inputProps={{
                                                style: {
                                                    cursor: 'not-allowed'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>}


                            {
                                preAuthFormData[0]?.EstimatedLengthOfUse !== undefined && preAuthFormData[0]?.EstimatedLengthOfUse !== '' &&
                                <Grid container>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <FormControl className={`${styles.spacingRemoveTop} width100per`}>
                                            <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">
                                                9. {t("PreAuthForm.EstimatedLengthOfUse")}: <span className={styles.asterisk}>*</span>
                                            </FormLabel>
                                            <RadioGroup
                                                value={preAuthFormData[0].EstimatedLengthOfUse}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                            >
                                                <FormControlLabel value="1" control={<Radio />} label="PRN" disabled={true} sx={{ paddingLeft: '25px' }}/>
                                                <FormControlLabel value="2" control={<Radio />} label="1x use" disabled={true} />
                                                <FormControlLabel value="3" control={<Radio />} label="Short term (specify)" disabled={true} />
                                                <FormControlLabel value="4" control={<Radio />} label="Other (specify)" disabled={true} />
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    size="small"
                                                    value={preAuthFormData[0].OthertermSpecify}
                                                    aria-label="minimum height"
                                                    sx={{
                                                        marginTop: '5px', cursor: 'not-allowed', backgroundColor: 'var(--color-disableField)',
                                                        '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                                                    borderRadius:"8px"
                                                    }}
                                                    disabled={true}
                                                    inputProps={{
                                                        style: {
                                                            cursor: 'not-allowed'
                                                        }
                                                    }}
                                                />

                                            </RadioGroup>

                                        </FormControl>
                                    </Grid>
                                </Grid>}



                            {
                                preAuthFormData[0]?.IsFacilityRequested !== undefined && preAuthFormData[0]?.IsFacilityRequested !== '' &&
                                <Grid container>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <FormControl className={styles.spacingRemoveTop}>
                                            <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">
                                                10. {t("PreAuthForm.facilityRequested")}<span className={styles.asterisk}> *</span>
                                            </FormLabel>
                                            <RadioGroup
                                                value={preAuthFormData[0].IsFacilityRequested}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                sx={{ paddingLeft: '25px' }}
                                            >
                                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} />
                                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>}



                            {
                                preAuthFormData[0]?.FacilityRequestingName !== undefined && preAuthFormData[0]?.FacilityRequestingName !== '' &&
                                <Grid container sx={{ paddingLeft: '25px' }}>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <div>
                                            <Typography className={styles.labelText}>
                                            {t("PreAuthForm.requestingFacilityName")}: <span className={styles.asterisk}> *</span>
                                            </Typography>
                                        </div>
                                        <TextField
                                            minRows={2}
                                            aria-label="requesting custom text"
                                            size="small"
                                            fullWidth
                                            value={preAuthFormData[0].FacilityRequestingName}
                                            sx={{
                                                marginTop: '5px', cursor: 'not-allowed', backgroundColor: 'var(--color-disableField)',
                                                '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                                                borderRadius:"8px"
                                            }}
                                            disabled={true}
                                            inputProps={{
                                                style: {
                                                    cursor: 'not-allowed'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>}

                            {
                                preAuthFormData[0]?.ClinicianName !== undefined && preAuthFormData[0]?.ClinicianName !== '' &&
                                <Grid container>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <div>
                                            <Typography className={styles.labelText}>
                                            {t("PreAuthForm.clinicalName")} <span className={styles.asterisk}>*</span>
                                            </Typography>
                                        </div>
                                        <TextField
                                            aria-label="clinic text field"
                                            value={preAuthFormData[0].ClinicianName}
                                            fullWidth
                                            minRows={2}
                                            size="small"
                                            sx={{
                                                marginTop: '5px', cursor: 'not-allowed', backgroundColor: 'var(--color-disableField)',
                                                '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                                                borderRadius:"8px"
                                            }}
                                            disabled={true}
                                            inputProps={{
                                                style: {
                                                    cursor: 'not-allowed'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>}

                            {
                                preAuthFormData[0]?.AdministratorOrED !== undefined && preAuthFormData[0]?.AdministratorOrED !== '' &&
                                <Grid container>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <div>
                                            <Typography className={styles.labelText}>
                                            {t("PreAuthForm.edAdmin")} <span className={styles.asterisk}>*</span>
                                            </Typography>
                                        </div>
                                        <TextField
                                            fullWidth
                                            aria-label="Admin field"
                                            minRows={2}
                                            value={preAuthFormData[0].AdministratorOrED}
                                            size="small"
                                            sx={{ marginTop: '5px', cursor: 'not-allowed', backgroundColor: 'var(--color-disableField)',
                                                '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                                                    borderRadius:"8px"
                                             }}
                                            disabled={true}
                                            inputProps={{ style: { cursor: 'not-allowed' } }}
                                        />
                                    </Grid>
                                </Grid>}

                            {
                                preAuthFormData[0]?.AVPO !== undefined && preAuthFormData[0]?.AVPO !== '' &&
                                <Grid container>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <div>
                                            <Typography className={styles.labelText}>
                                            {t("PreAuthForm.avpo")} <span className={styles.asterisk}>*</span>
                                            </Typography>
                                        </div>
                                        <TextField
                                            fullWidth
                                            aria-label="avpo field"
                                            minRows={2}
                                            value={preAuthFormData[0].AVPO}
                                            size="small"
                                            sx={{ marginTop: '5px', backgroundColor: 'var(--color-disableField)',
                                                '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                                                    borderRadius:"8px"
                                             }}
                                            disabled={true}
                                            inputProps={{ style: { cursor: 'not-allowed' } }}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        </DialogContent>

                        <Grid container sx={{ justifyContent: 'end' }}>
                            <Grid>
                                <DialogActions style={{ justifyContent: "end", padding: "14px 16px" }} >
                                    <Button
                                        onClick={(e) => { e.preventDefault(); handleClose(); }}
                                        variant="contained"
                                        className='secondaryButton'
                                    >
                                        {t('NewOrder.close')}
                                    </Button>
                                </DialogActions>
                            </Grid>
                        </Grid>

                    </Dialog>
                )}
        </>
    )
}

export default PortableOxygenConcentrator;