import React, { useEffect, useState } from "react";
import styles from "./ViewOtherOrders.module.scss";
import style from "../OrdersDetails/OrdersDetails.module.scss";
import { Card, CardContent, Chip, Grid, Tooltip, Typography} from "@mui/material";
import { Box, Stack } from "@mui/system";
import FollowButton from "../../../components/followButton/followButton";
import { Button } from "@mui/base";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useTranslation } from "react-i18next";
import { OrdersComponentApi, GenerateReceiptApi } from "../../../constants/ApiConstants";
import { HTTPMethod, callApi as OrderDetailRequest, callApi as recurringOrdersRequest } from "../../../services/HttpService/HttpService";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate,Link } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import Breadcrumb from "../../../components/Breadcrumb/MuiBreadcrumb";
import NoData from "../../../components/noData/noData";
import loader from "../../../../src/assets/loader.gif";
import CustomTablePagination, { CustomPagination } from "../../../components/CustomTablePagination/CustomTablePagination";
import { EntityTypeForFollow, deliveryOrderType, statusEnum } from "../../../common/enums";
import { produce } from 'immer';
import CancelOrder from "../../../components/CancelOrder/CancelOrder";
import { successToast, errorToast } from "../../../components/ToastMessage/ToastMessage";
import StatusColor from "../../../common/Utils/StatusColor/StatusColor";
import ReasonCode from "../../../common/Utils/ReasonCode/ReasonCode";
import PriorityIcon from "../../../common/Utils/PriorityIcon/PriorityIcon/PriorityIcon";
import ETA from "../../../components/ETA/ETA";
import MapLocationButton from "../../../components/MapLocationButton/MapLocationButton";
import OrderTypeText from "../../../common/Utils/OrderTypeText/OrderTypeText";
import EditOrderButton from "../../../components/editOrderButton/EditOrderButton";
import OrderType from "../../../components/OrderType/OrderType";
import { useSelector } from "react-redux";

const ViewOtherOrders = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [orderDetailsData, setOrdersDetailsData] = useState([]);
  const [viewOtherOrderData, setViewOtherOrderData] = useState([]);
  const orderId = location?.pathname.split("/").pop();
  const [followButtonStates, setFollowButtonStates] = useState(new Array(orderDetailsData?.length).fill(false));
  const [totalRecords, setTotalRecords] = useState(10);
  const [isPaginationLoading, setPaginationLoading] = useState(false);
  const { page, rowsPerPage, offset, handleChangePage, handleChangeRowsPerPage} = CustomPagination();
  const [isLoading, setLoading] = useState(true);
  const [firstTimeRun, setFirstTimeRun] = useState(true);
  const { state } = location;
  const navigateFromSupply = state && state.navigateFromSupply;
  const [isCancelOrderPopupOpen, setIsCancelOrderPopupOpen] = useState(false);
  const [alertEquipemntName, setAlertEquipemntName] = useState(null);
  const [cancelReason, setCancelReason] = useState('');
  const userId = useSelector(state => state.authState.userData?.data?.user_info?.user_id ?? 0);
  const breadcrumbItems = [
    { label: "Orders", link: `/orders` },
    { label: orderId },
  ];

  useEffect(() => {
    if (navigateFromSupply) {
      handleSupplyDetails()
    } else {
      handleOrdersDetails();
    }
  }, []);

  const handleSupplyDetails = async () => {
    const response = await OrderDetailRequest(
      HTTPMethod.Get,
      OrdersComponentApi.otherSupplyDetails + "/" + orderId
    );
    if (response && response.status_code == 200 && response.data) {
      setOrdersDetailsData(response?.data?.supply_details);
      setFirstTimeRun(false);
    } else {
      setLoading(false);
    }
  };

  const handleOrdersDetails = async () => {
    const response = await OrderDetailRequest(
      HTTPMethod.Get,
      OrdersComponentApi.orderDetails + "/" + orderId
    );
    if (response && response.status_code == 200 && response.data) {
      setOrdersDetailsData(response.data);
      setFirstTimeRun(false);
    } else {
      setLoading(false);
    }
  };
  const handleViewOtherOrders = async () => {
    setLoading(true);
    setViewOtherOrderData([]);
    const requestBody = {
      patient_id: orderDetailsData?.patient_info?.patient_id,
      hospice_branch_id: orderDetailsData?.orders?.branch_id,
      limit: rowsPerPage,
      offset: offset,
      order_id: orderDetailsData?.orders?.order_id ?? orderDetailsData?.orders?.purchase_order_id,
      user_id:userId
    };
      const response = await OrderDetailRequest(
        HTTPMethod.Post,
        OrdersComponentApi.viewOtherOrders,
        requestBody
      );
      if (response && response.status_code == 200 && response.data) {
        setViewOtherOrderData(response.data.other_orders);
        setFollowButtonStates(response?.data?.other_orders?.map(item => item?.is_followed === true))
        setTotalRecords(response.data.total_count);
      }
      setLoading(false);
      setPaginationLoading(false);
  };

  useEffect(() => {
    if (!firstTimeRun && userId) {
      handleViewOtherOrders();
    }
  }, [offset, rowsPerPage, firstTimeRun,userId]);

  const handleOrderDetailsPage = (order_id, order_type) => {
    if (order_type === "Supplies" || order_type === deliveryOrderType.Supplies) {
      navigate("/orders/supply-order-details/" + order_id)
    }
    else {
      navigate("/orders/order-details/" + order_id);
    }
  };

  const handlePdfOtherOrders = async (e,orderId) => {  
    const pdfAPIResponse = await OrderDetailRequest(HTTPMethod.Get, GenerateReceiptApi.generateReceipt + "?order_id=" + orderId)
    if (pdfAPIResponse && pdfAPIResponse.status_code == 200) {
        if (pdfAPIResponse.data) {
          const pdfCreateUrl = pdfAPIResponse.data.receipt_order_url;
          window.open(pdfCreateUrl)
        }
    }
  }

  const handleRemoveOrder = async () => {
    setIsCancelOrderPopupOpen(false)
    const requestBody = {
      order_id: alertEquipemntName,
      cancel_reason: cancelReason.trim()
    }
      const response = await recurringOrdersRequest(
        HTTPMethod.Put,
        OrdersComponentApi.cancelOrder,
        requestBody,
        "event-cancel-order"
      );
      setCancelReason("");
      if (response && response.status_code == 200) {
        const message = response?.return_message[0];
        successToast(message);
        handleViewOtherOrders();

      }
      else {
        errorToast("There was a problem. Please contact technical support.");
      }
  }

    return (
    <>
    <div className={styles.ViewOtherOrders} data-testid="ViewOtherOrders">
      <Breadcrumb items={breadcrumbItems} />
      <div
        style={{
          fontSize: "var(--main-heading)",
          marginBottom: "15px",
          fontWeight: 600,
          marginTop: "15px",
          display: "flex",
          alignItems: "center"
        }}
      >
        {" "}
        <ArrowBackIosIcon sx={{cursor: "pointer"}} data-testid="otherOrder" onClick={() => {handleOrderDetailsPage(orderId,orderDetailsData?.orders?.order_type)}}/>
        <p>
        <span>Other orders for</span> 
        {orderDetailsData?.patient_info?.patient_last_name && (
          <span>
          {" "}{orderDetailsData?.patient_info?.patient_last_name},
          </span>
        )}
        {orderDetailsData?.patient_info?.patient_first_name && (
          <span>
            &nbsp;{orderDetailsData?.patient_info?.patient_first_name}
          </span>
        )}
        {orderDetailsData?.patient_info?.patient_middle_initial && (
          <span>&nbsp;{orderDetailsData?.patient_info?.patient_middle_initial}</span>
        )}
        </p>
      </div>

      {viewOtherOrderData?.length > 0 ? (
        <div>
          <div className={styles.mainContainer}>
            {viewOtherOrderData?.map((item, index) => {
              return (
                <Card variant="outlined" key={item.order_id} sx={{
                  textAlign: "left",
                  borderRadius: "0px",
                  borderBottom: "1px solid rgba(0,0,0,0.12)",
                  borderRight: "none", borderTop: "none", borderLeft: "none",
                }}>

                  <CardContent className="cursorPointer" >
                    <Link className="textDecoration"  to={ (item.order_type == "Supplies" || item.order_type == deliveryOrderType.Supplies) ? `/orders/supply-order-details/${item.order_id}` : `/orders/order-details/${item.order_id}`  } >
                    <Typography>
                      <Box
                        display="flex"
                        flexDirection={{ xs: "column", sm: "row" }}
                        justifyContent="space-between"
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            paddingBottom: "5px",
                            fontSize: "25px",
                            fontWeight: 600,
                          }}
                        >
                          <p className={`${styles.marginClass} colorBlack`}>{item.order_id}</p>
                        </Typography>
                        {!(
                          item?.order_status == "Canceled" ||
                          item?.order_status == "Complete" ||
                          item?.order_status == "Delivered" ||
                          item?.order_status == "Picked Up"
                        ) && (
                            <Stack
                              direction="row"
                              spacing={2}
                              display="flex"
                              marginBottom={"5px"}
                              alignItems={{ sm: "flex-start" }}
                              justifyContent={{
                                xs: "flex-start",
                                sm: "space-between",
                              }}
                            >

                              <div className="follow-button-container"  onClick={(e) => { e.stopPropagation() }}>
                                <FollowButton
                                  toggleValue={followButtonStates[index]}
                                  toggleSetValue={(value) => {
                                    const updatedStates = [...followButtonStates];
                                    updatedStates[index] = value;
                                    setFollowButtonStates(updatedStates);
                                    setViewOtherOrderData(produce(draft => {
                                      draft[index].is_followed = value
                                    }))
                                  }}
                                  followPayload = {{
                                    entityId:item.order_id,
                                    userId:[],
                                    entityType: item.order_type == "Supplies" || item.order_type == deliveryOrderType.Supplies ? EntityTypeForFollow.SupplyOrder : EntityTypeForFollow.Order,
                                  }}
                                  />
                              </div>

                            </Stack>
                          )}
                        
                      </Box>
                      <Box style={{ position: "relative" }}>
                        <div className={`${styles.containerClass} ${styles.chipWithEtaAndMap}`}  onClick={(e) => { e.preventDefault() }}>
                          <div className={`${styles.chipEtaFlex} ${styles.statusButtons}`}>
                            <Chip
                              label={item.order_status}
                              size="small"
                              alignItems={{ sm: "flex-start" }}
                              className={styles.chip}
                              sx={{
                                backgroundColor: StatusColor(
                                  item.order_status
                                ),
                              }}
                            ></Chip>

                            {(item.order_status === statusEnum["On my way"] || item.order_status === statusEnum["En-Route"] || item.order_status === statusEnum["Picking Up"]) && (
                              <ETA manualEta={item.eta} orderStatus={item.order_status} arrivalTime={item?.arrival_time} formattedArrivalTime={item?.arrival_time_formatted} isDelayed={item?.is_eta_delayed} etaWindow={item?.eta_window_excepted_time}/>
                            )}
                          </div>

                          <Stack className={styles.mobileView}
                            direction="row"
                            display="flex"
                            alignItems={{ sm: "flex-start" }}
                            justifyContent={{
                              xs: "flex-start",
                            }}
                          >

                            {item.order_status !== 'Canceled' && (
                              <>
                                {item.is_view_receipt_link_visible ===
                                  true &&
                                  item.receipt_link_name ===
                                  "Work order" && (
                                    <Tooltip
                                      title={
                                        <span style={{ fontSize: "13px" }}>
                                          Work Order
                                        </span>
                                      }>
                                      <Button className='blueIconButton'
                                      data-testid="WorkOrder"
                                        onClick={(e) => {
                                          handlePdfOtherOrders(e,item.order_id);
                                        }}>
                                        <TaskAltIcon
                                          className={styles.customSizeImage}
                                        />
                                      </Button>
                                    </Tooltip>
                                  )}
                                {item.is_view_receipt_link_visible ===
                                  true &&
                                  item.receipt_link_name ===
                                  "View Receipt" && 
                                  (
                                    <Tooltip
                                      title={
                                        <span style={{ fontSize: "13px" }}>
                                          View Receipt
                                        </span>
                                      }>
                                      <Button className='blueIconButton marginLeftEight'
                                        data-testid = {"ViewReceipt" + index}
                                        onClick={(e) => {
                                          handlePdfOtherOrders(e,item.order_id);
                                        }}>
                                        <ReceiptLongIcon
                                          className={styles.customSizeImage}
                                        />
                                      </Button>
                                    </Tooltip>
                                  )}
                              </>
                            )}

                            {(item?.order_status == "Received" ) && (
                                <EditOrderButton data={item} index={index} />
                            )}

                            {(item?.order_status == "Received" ) && (
                                <Tooltip
                                  title={
                                    <span style={{ fontSize: "13px" }}>
                                      Cancel Order
                                    </span>
                                  }
                                >
                                  <Button className='redIconButton marginLeftEight'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setIsCancelOrderPopupOpen(true)
                                      setAlertEquipemntName(item.order_id)
                                    }}
                                    variant="contained" size="small">
                                    <ClearIcon
                                      data-testid={"ClearIcon" + index}
                                      className={styles.customSizeImage}
                                    />
                                  </Button>
                                </Tooltip>
                              )}

                            {(item.order_status === statusEnum["On my way"] || item.order_status === statusEnum["En-Route"] || item.order_status === statusEnum["Picking Up"]) && (
                              <div className={styles.maplocation} data-testid="mapLocation" onClick={(event) => event.preventDefault()}>
                                <MapLocationButton orderStatus={item.order_status} eta={item.eta} orderId={item.order_id} latitude={item.latitude} longitude={item.longitude} addressLine1={item?.address_line1} addressLine2={item?.address_line2} city={item?.city} state={item?.state} zipcode={item?.zip_code} country={item?.country} arrivalTime={item?.arrival_time} formattedArrivalTime={item?.arrival_time_formatted} isDelayed={item?.is_eta_delayed} etaWindow={item?.eta_window_excepted_time}/>
                              </div>
                            )}
                          </Stack>
                        </div>
                        <Box
                          display="flex"
                          flexDirection={{ xs: "column", sm: "row" }}
                          alignItems={{ sm: "flex-end" }}
                          justifyContent={{
                            xs: "flex-start",
                            sm: "space-between",
                          }}
                        >
                          <Grid container>
                            <Grid item xs={12} md={6}>
                              <Typography
                                sx={{
                                  display: "flex",
                                  fontSize: "25px",
                                  fontWeight: 300,
                                }}
                              >
                                <p className={styles.marginClass}>


                                  {item.total_equipment !== 0 && (
                                    <Typography>
                                        {item.order_type == 'Supplies' ?
                                          <span className={styles.customTextLabel}>  {t("NewOrder.uniqueSupplyItems")}:</span> :
                                          <span className={styles.customTextLabel}> {t("Dashboard.uniqueEquipment")}: </span>
                                        }
                                      <span className={styles.customText}>
                                        {item.total_equipment}
                                      </span>
                                    </Typography>
                                  )}
                                  {item.equipment_requiring_approval > 0 && (
                                    <Typography>
                                      <span className={styles.customTextLabel}>
                                        Equipment requiring approval:
                                      </span>
                                      <span className={styles.customText}>
                                        {item.equipment_requiring_approval}
                                      </span>
                                    </Typography>
                                  )}
                                  {item.ordered_on && (
                                    <Typography>
                                      <span className={styles.customTextLabel}>
                                        Ordered on:
                                      </span>
                                      <span className={styles.customText}>
                                        {item.ordered_on}
                                      </span>
                                    </Typography>
                                  )}
                                  {item.ordered_by && (
                                    <Typography>
                                      <span className={styles.customTextLabel}>
                                        Ordered by:
                                      </span>
                                      <span className={styles.customText}>
                                        {item.ordered_by} (
                                        {item.ordered_by_company_name})
                                      </span>
                                    </Typography>
                                  )}
                                  {item.reason && (
                                    <Typography>
                                      <span className={styles.customTextLabel}>
                                        Order Reason:
                                      </span>
                                      <span className={styles.customText}>
                                        {item.reason}
                                      </span>
                                    </Typography>
                                  )}
                                  {item.priority_name && (
                                <Typography>
                                  <span className={styles.customTextLabel}>
                                    Priority:
                                  </span>
                                  <span className={styles.customText}>
                                    {item.priority_name}
                                  </span>
                                </Typography>
                              )}
                                </p>
                              </Typography>
                           
                              {item?.associated_nurse_list &&
                                item?.associated_nurse_list.length > 0 &&
                                item?.associated_nurse_list.some((nurse) => nurse?.nurse_name !== null || nurse?.nurse_phone !== null) && (
                                <Grid item>
                                  <Box
                                    flex="1"
                                    display="flex"
                                    alignItems={{ xs: "flex-start" }}
                                    flexDirection="column"
                                    sx={{ marginTop: { xs: 1, md: 0 } }}
                                  >
                                    <div
                                      className={styles.clipAlignment}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        className={styles.customTextLabel}
                                        style={{
                                          marginRight: "10px",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        Associated nurse:
                                        </span>
                                        {item?.associated_nurse_list?.map(
                                          (nurse) => (
                                            <div
                                              key={nurse.nurse_name}
                                              style={{ display: "flex", alignItems: "center" }}
                                            >
                                              <Tooltip
                                                key={nurse.nurse_name}
                                                title={
                                                  nurse?.nurse_phone && (
                                                    <span className={style.tooltipStyling}>
                                                      {nurse?.nurse_phone?.replace(
                                                        /(\d{3})(\d{3})(\d{4})/,
                                                        "($1) $2-$3"
                                                      )}
                                                    </span>
                                                  )
                                                }
                                              >
                                                {nurse?.nurse_name && (
                                                  <div key={nurse.nurse_name}>
                                                    <Chip
                                                      label={nurse?.nurse_name}
                                                      size="small"
                                                      className={style.chipMargin}
                                                    />
                                                  </div>
                                                )}
                                              </Tooltip>
                                            </div>
                                          )
                                        )}
                                    </div>
                                  </Box>
                                </Grid>
                              )}
                              {item?.recurring_order_profile_id > 0 && (
                                <Grid item>
                                  <Typography sx={{ marginBottom: "0px" }}>
                                    <span className={styles.customTextLabel}>
                                      Recurring order:
                                    </span>
                                    <span
                                      style={{ marginLeft: "5px" }}
                                      className={styles.customLink}
                                    >
                                      <Link to={`/recurring-orders/recurring-order-details/${item.recurring_order_profile_id}`}
                                          onClick={(e) => {
                                            e.stopPropagation()
                                          }}
                                          data-testid ={ "RecurringDetailPage" + index}
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "700",
                                            color: "var(--color-base)",
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            marginLeft: '5px'
                                          }}
                                        >
                                           {item?.recurring_order_profile_id}
                                        </Link>
                                    </span>
                                  </Typography>
                                </Grid>
                              )}
                              {item?.approved_on && (
                                <Grid item>
                                  <Typography sx={{ marginBottom: "0px" }}>
                                    <span className={styles.customTextLabel}>
                                      Approved on:
                                    </span>
                                    <span className={styles.customText}>
                                      {item?.approved_on}
                                    </span>
                                  </Typography>
                                </Grid>
                              )}
                              {item?.approved_by && (
                                <Grid item>
                                  <Typography sx={{ marginBottom: "0px" }}>
                                    <span className={styles.customTextLabel}>
                                      Approved by:
                                    </span>
                                    <span className={styles.customText}>
                                      {item?.approved_by}
                                    </span>
                                  </Typography>
                                </Grid>
                              )}
                              {item?.rejected_on && (
                                <Typography sx={{ marginBottom: "0px" }}>
                                  <span className={styles.customTextLabel}>
                                    Rejected on:
                                  </span>
                                  <span className={styles.customText}>
                                    {item?.rejected_on}
                                  </span>
                                </Typography>
                              )}
                              {item?.rejected_by && (
                                <Typography sx={{ marginBottom: "0px" }}>
                                  <span className={styles.customTextLabel}>
                                    Rejected by:
                                  </span>
                                  <span className={styles.customText}>
                                    {item?.rejected_by}
                                  </span>
                                </Typography>
                              )}
                              <Typography
                                sx={{
                                  fontSize: "var(--content-font-size-16px)",
                                }}
                              >
                                  <OrderTypeText
                                    orderType={item.order_type}
                                    orderStatus={item.order_status}
                                    deliverOn={item.delivered_on}
                                    deliverBy={item.deliver_by}
                                    pickedUpOn={item.delivered_on}
                                    pickupBy={item.pickup_by}
                                    serviceOn={item.delivered_on}
                                    serviceBy={item.service_by}
                                    mixOrderConfiguration={item?.mix_order_configuration}
                                  />
                              </Typography>
                              {item.canceled_on && (
                                <Grid item>
                                  <Typography sx={{ marginBottom: "0px" }}>
                                    <span className={styles.customTextLabel}>
                                      Canceled on:
                                    </span>
                                    <span className={styles.customText}>
                                      {item.canceled_on}
                                    </span>
                                  </Typography>
                                </Grid>
                              )}
                              {item.canceled_by && (
                                <Grid item>
                                  <Typography sx={{ marginBottom: "0px" }}>
                                    <span className={styles.customTextLabel}>
                                      Canceled by:
                                    </span>
                                    <span className={styles.customText}>
                                      {item.canceled_by}
                                    </span>
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                            <div>
                            <Stack
                              direction="row"
                              spacing={2}
                              display="flex"
                              marginBottom="2px"
                              alignItems="center"
                              justifyContent="flex-start"
                              sx={{
                                fontSize: "var(--common-content)",
                              }}>
                            {PriorityIcon(item?.priority_name)}
                            {item?.reason && <Stack direction="column" alignItems="center">
                              {ReasonCode(item?.order_reason, item?.order_type)}
                              </Stack>} 
                              <OrderType mixOrderConfiguration={item?.mix_order_configuration}/>
                            </Stack>
                          </div>
                        </Box>
                      </Box>
                    </Typography>
                    </Link>
                  </CardContent>
                </Card>
              );
            })}
          </div>
          <div
            style={{
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              fontSize: "var(--common-heading)",
              marginTop: "30px",
            }}
          >
            <CustomTablePagination
              totalRecord={totalRecords}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              isLoading={isPaginationLoading}
            />
          </div>
        </div>
      ) : (
          <div>
            {isLoading && (
              <NoData
                message={
                  isLoading && <img alt="Loading..." src={loader} />
                }
              />
            )}
          </div>
      )}
    </div>
    <CancelOrder
          isOpen={isCancelOrderPopupOpen}
          handleCancel={() => setIsCancelOrderPopupOpen(false)}
          handleSubmit={handleRemoveOrder}
          orderName={alertEquipemntName}
          cancelReason={cancelReason}
          setCancelReason={setCancelReason}
          contentText={`Please enter the reason for canceling this order:`}
          contentText2={`Reason`}
          headerText="Cancel Order" cancelText="Go Back" okText="Proceed" />
    </>
  );
};
export default ViewOtherOrders;
