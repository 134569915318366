import React, { useCallback, useEffect, useState } from "react";
import { Box, Card, CardContent, Typography, InputLabel, Tooltip, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import {produce} from "immer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Chip from "@mui/material/Chip";
import FollowButton from "../../components/followButton/followButton";
import { HTTPMethod, callApi as patientsOrderRequest } from "../../services/HttpService/HttpService";
import styles from "./PatientsOrder.module.scss";
import {  OrdersComponentApi, PatientsDetailsApi } from "../../constants/ApiConstants";
import { useLocation, Link as RouterLink, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NoData from "../noData/noData";
import { LineOfBusinessEnum } from "../../constants/BusinessConstants";
import CustomTablePagination, { CustomPagination } from "../CustomTablePagination/CustomTablePagination";
import loader from "../../assets/loader.gif";
import { EntityTypeForFollow, PriorityNameEnum, statusEnum } from "../../common/enums";
import OrdersProofDetail from "../OrdersProofDetail/OrdersProofDetail";
import ReasonCode from "../../common/Utils/ReasonCode/ReasonCode";
import PriorityIcon from "../../common/Utils/PriorityIcon/PriorityIcon/PriorityIcon";
import ETA from "../ETA/ETA";
import MapLocationButton from "../MapLocationButton/MapLocationButton";
import OrderTypeText from "../../common/Utils/OrderTypeText/OrderTypeText";
import { mixedOrderTitle } from "../../common/Utils/CommonFunctions/CommonFunctions";
import OrderType from "../OrderType/OrderType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";

export const getOrderTitle = (mixOrderConfiguration, t, shouldWrapInSpan = true) => {
  let titleText;

  if(mixOrderConfiguration?.includes(","))
    return shouldWrapInSpan ? <span style={{ fontSize: "13px" }}>{mixedOrderTitle(mixOrderConfiguration)}</span> : mixedOrderTitle(mixOrderConfiguration);
  else{
    switch (mixOrderConfiguration) {
      case "P":
        titleText = t("ProofTitles.proofOfPickup");
        break;
      case "SR":
        titleText = t("ProofTitles.proofOfService");
        break;
      default:
        titleText = t("ProofTitles.proofOfDelivery");
    }
    return shouldWrapInSpan ? <span style={{ fontSize: "13px" }}>{titleText}</span> : titleText;
  }
};

const PatientsOrder = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [orderData, setOrdersData] = useState([]);
  const [proofData, setProofData] = useState(null);
  const location = useLocation();
  const [selectedValue, setSelectedValue] = useState("Most recent");
  const [sortedOrderData, setSortedOrderData] = useState(orderData);
  const { page, rowsPerPage, setPage, setRowsPerPage,  handleChangePage, handleChangeRowsPerPage} = CustomPagination();
  const [isLoading, setLoading] = useState(true);
  const [isPaginationLoading, setPaginationLoading] = useState(false);
  let showMessage = `No orders exist for this ${LineOfBusinessEnum.patientToMember.toString().toLowerCase()}.`;
  const [proofOfDelivery, setProofOfDelivery] = useState(null);

  const fetchOrdersData = useCallback(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const newData = orderData?.slice(startIndex, endIndex);
    setSortedOrderData(newData);
    setPaginationLoading(false);
  }, [page, orderData, rowsPerPage]);
  useEffect(() => {
    fetchOrdersData();
  }, [fetchOrdersData]);

  useEffect(() => {
    const fetchOrdersData = async () => {
      setLoading(true);
      const requestBody = location.pathname.split("/").pop();
        const apiEndpoint =
          PatientsDetailsApi.patientsOrderTab + "/" + requestBody + "/orders";

        const response = await patientsOrderRequest(
          HTTPMethod.Get,
          apiEndpoint
        );

        if (response && response.status_code === 200 && response.data) {
          setOrdersData(response.data?.orders);
          setLoading(false);
          setPaginationLoading(false);
        } else {
          setLoading(false);
          setPaginationLoading(false);
        }
    };
    fetchOrdersData();
  }, []);

  const fetchProofData = async (orderId) => {
    const proofResponse = await patientsOrderRequest(
      HTTPMethod.Get,
      OrdersComponentApi.orderProofOfDelivery.replace(
        "{order_id}",
        orderId
      )
    );
    if (proofResponse && proofResponse?.status_code == 200 && proofResponse?.data) {
      setProofData(proofResponse.data);
    }
  }

  let statusButtonColors = [
    "#FAC300 ",
    "#D2322D ",
    "#619633 ",
    "#FF7300 ",
    "#25A8E0 ",
    "#777777 ",
    "#B6B6B6 ",
  ];

  function statusColorForChip(orderStatus, firstAcknowledged) {
    switch (orderStatus) {
      case "Pending Approval":
        return { text: orderStatus, color: statusButtonColors[0] };
      case "ApprovalEscalated":
        return { text: orderStatus, color: statusButtonColors[1] };
      case "On my way":
        return { text: orderStatus, color: statusButtonColors[2] };
      case "Pending":
      case "En-Route":
      case "Shipping":
      case "Picking Up":
        return { text: orderStatus, color: statusButtonColors[3] };
      case "Delivered": case "Picked Up":
        return { text: orderStatus, color: statusButtonColors[4] };
      case "Received":
        if (firstAcknowledged < 0 || firstAcknowledged != null) {
          return { text: "Acknowledged", color: statusButtonColors[0] };
        } else {
          return { text: "Received", color: statusButtonColors[5] };
        }
      case "Submitted":
      case "Queued":
        return { text: orderStatus, color: statusButtonColors[5] };
      case "Canceled":
        return { text: orderStatus, color: statusButtonColors[6] };
      default:
        return { text: orderStatus, color: statusButtonColors[0] };
    }
  }

  const handleChange = (event) => {
    if (event) setSelectedValue(event.target.value);
    handleSortChange(event.target.value);
  };
  
  const handleSortChange = (sortType) => {
    setRowsPerPage(25);
    setPage(0);
    let sortedData = [...orderData];

    switch (sortType) {
      case "Most recent":
        return sortedData;
      case "Oldest":
        sortedData = sortedData.reverse();
        break;
      case "Order number (ascending)":
        sortedData = sortedData.sort((a, b) => {
          return a.order_id - b.order_id;
        });
        break;
      case "Order number (descending)":
        sortedData = sortedData.sort((a, b) => {
          return b.order_id - a.order_id;
        });
        break;
      default:
        break;
    }

    setSortedOrderData(sortedData);
    setSelectedValue(sortType);
  };

  const handleApproveOrder = (e, order_id) => {
    e.preventDefault();
    localStorage.setItem('approveOrder','listing');
    navigate("/order-approvals/order-approval-details/" + order_id);
  }
  return (
    <>
      <div className={styles.PatientsOrder} data-testid="PatientsOrder">
        {sortedOrderData.length > 0 ? (
          <div className={styles.dataMargin}>
            <Typography
              sx={{
                marginTop: "15px",
                width: isMobileScreen ? "100%" : "30%",
                marginLeft: "auto",
                marginRight: "2px",
              }}
            >
              <Box sx={{ minWidth: 120, marginBottom:'50px' }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Sort by:
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedValue}
                    label="Sort by:"
                    onChange={handleChange}
                    sx={{ height: "3rem" }}
                    data-testid="select"
                  >
                    <MenuItem value="Most recent" data-testid = "MostRecent">Most recent</MenuItem>
                    <MenuItem value="Oldest" data-testid = "Oldest">Oldest</MenuItem>
                    <MenuItem value="Order number (ascending)" data-testid = "ascending">
                      Order number (ascending)
                    </MenuItem>
                    <MenuItem value="Order number (descending)" data-testid = "descending">
                      Order number (descending)
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Typography>
            <div className={styles.mainContainer}>
              {sortedOrderData?.map((item, index) => {
                return (
                  <div className={styles.customDiv} key={item.order_id}>
                    <Card variant="outlined" sx={{ textAlign: "left" , borderRadius: "0px" ,borderBottom: "1px solid rgba(0,0,0,0.12)"}}>
                      <CardContent data-testid='card-content'>
                        <RouterLink className="textDecoration" to={`/orders/order-details/${item.order_id}`} >
                        <Typography>
                          <Box
                            display="flex"
                            flexDirection={{ xs: "column", sm: "row" }}
                            alignItems={{ sm: "flex-start" }}
                            justifyContent={{
                              xs: "flex-start",
                              sm: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                display: "flex",
                                paddingBottom: "5px",
                                fontSize: "24px",
                                fontWeight: 600,
                              }}
                            >
                              <p className={`${styles.marginClass} colorBlack`}>
                                {item.order_id}
                              </p>
                            </Typography>
                            <Stack
                              direction="row"
                              spacing={2}
                              display="flex"
                              marginBottom={"10px"}
                              alignItems={{ sm: "flex-start" }}
                              justifyContent={{
                                xs: "flex-start",
                                sm: "space-between",
                              }}
                            >
                              {(item.order_status !== 'Delivered' && item.order_status !== 'Canceled' && item.order_status !== 'Picked Up') && <div className="follow-button-container"  onClick={(e) => { e.stopPropagation() }}>
                              <FollowButton
                                  toggleValue={item?.followed}
                                  toggleSetValue={(value) => {
                                    setOrdersData(produce(draft => {
                                      draft[index].followed = value;
                                    }))
                                  }}
                                  followPayload = {{
                                    entityId:item?.order_id,
                                    userId:[],
                                    entityType:EntityTypeForFollow.Order,
                                  }}
                                />
                              </div>}
                            </Stack>
                          </Box>
                          <Box style={{ position: "relative" }}>
                            <div className="flexJustifyBetween">
                              <div className={styles.statusButtons}>
                                <Chip
                                  label={statusColorForChip(item.order_status, item.first_acknowledged_by).text}
                                  size="small"
                                  sx={{
                                    paddingTop:'1px',
                                    color: "var(--bg-white)",
                                    borderRadius: "6px",
                                    fontWeight: 800,
                                    fontSize: "13px"
                                  }}
                                  className={styles.buttonText}
                                  style={{
                                    backgroundColor: statusColorForChip(item.order_status, item.first_acknowledged_by).color,
                                  }}
                                ></Chip>
                                {(item.order_status === statusEnum["On my way"] || item.order_status === statusEnum["En-Route"] || item.order_status === statusEnum["Picking Up"]) && (
                                  <ETA manualEta={item?.eta} 
                                    orderStatus={item.order_status} 
                                    arrivalTime={item?.arrival_time} 
                                    formattedArrivalTime={item?.arrival_time_formatted} 
                                    isDelayed={item?.is_eta_delayed} 
                                    etaWindow={item?.eta_window_excepted_time}/>
                                )}
                              </div>
                              
                              
                                <div className="displayFlex">
                                  {
                                    (item?.order_status === "Pending Approval" && item?.has_approval_access) &&
                                    <Tooltip
                                      title={
                                        <span style={{ fontSize: "13px" }}>
                                          Approve Order
                                        </span>
                                      }
                                    >
                                      <Button variant="contained" size="small" className={styles.blueButton} onClick={(e) => handleApproveOrder(e, item?.order_id)}>
                                        <FontAwesomeIcon icon={faClipboardCheck} className={styles.clipboardStyle} />
                                      </Button>
                                    </Tooltip>
                                  }
                                  {item?.is_proof_of_delivery && <Tooltip enterTouchDelay={0}
                                    title={getOrderTitle(item?.mix_order_configuration, t)}
                                  >
                                    <Button className='blueIconButton' data-testid={'receiptButton' + index}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (item?.mix_order_configuration?.includes(",")) {
                                          navigate("/orders/mix-order-proof", { state: { data: item } });
                                        } else {
                                          fetchProofData(item.order_id);
                                          setProofOfDelivery(item);
                                        }
                                      }}
                                    >
                                      <PhotoLibraryOutlinedIcon className={styles.customSizeImage}
                                      />
                                    </Button>
                                  </Tooltip>}

                                  {(item.order_status === statusEnum["On my way"] || item.order_status === statusEnum["En-Route"] || item.order_status === statusEnum["Picking Up"]) && (
                                <div className="marginLeftEight" onClick={(event) => event.preventDefault()} data-testid='mapLocationDiv'>
                                  <MapLocationButton  orderStatus={item.order_status} eta={item.eta} orderId={item.order_id} latitude={item.latitude} longitude={item.longitude} addressLine1={item.address_line1} addressLine2={item.address_line2} city={item.city} state={item.state} zipcode={item.zip_code} country={item.country} arrivalTime={item?.arrival_time} formattedArrivalTime={item?.arrival_time_formatted} isDelayed={item?.is_eta_delayed} etaWindow={item?.eta_window_excepted_time}/>
                                </div>
                              )}
                              </div>
                            </div>
                            {item.total_equipment != 0 && (
                              <Typography
                                className={styles.scopedClassNameLine2}
                                sx={{
                                  paddingBottom: "5px",
                                  marginTop: "5px",
                                  fontSize: "var(--content-font-size-16px)",
                                }}
                              >
                                {item.order_type == 'Supplies' ? 
                                    <span className={styles.content}>  {t("NewOrder.uniqueSupplyItems")}:</span> : 
                                    <span className={styles.content}> {t("Dashboard.uniqueEquipment")}: </span>
                                }
                                <span className={styles.scopedClassColorText2}>
                                  {item.total_equipment}
                                </span>
                              </Typography>
                            )}

                            {item.equipment_requiring_approval != 0 && (
                              <Typography
                                className={styles.scopedClassNameLine2}
                                sx={{
                                  paddingBottom: "5px",
                                  fontSize: "var(--content-font-size-16px)",
                                }}
                              >
                                <span className={styles.content}>
                                  {" "}
                                  {t(
                                    "OrdersTab.equipmentRequiringApproval"
                                  )}:{" "}
                                </span>
                                <span className={styles.scopedClassColorText2}>
                                  {item.equipment_requiring_approval.toString()}
                                </span>
                              </Typography>
                            )}

                            <Typography
                              className={styles.scopedClassNameLine2}
                              sx={{
                                paddingBottom: "5px",
                                fontSize: "var(--content-font-size-16px)",
                              }}
                            >
                              <span className={styles.content}> {t("PatientDetails.provider")}: </span>
                              <span className={styles.scopedClassColorText2}>
                                {item.provider[0].provider_name}
                              </span>
                            </Typography>

                            <Typography
                              className={styles.scopedClassNameLine2}
                              sx={{
                                paddingBottom: "5px",
                                fontSize: "var(--content-font-size-16px)",
                              }}
                            >
                              <span className={styles.content}> {t("OrdersTab.orderedBy")}: </span>
                              <span className={styles.scopedClassColorText2}>
                              {item.ordered_by}({item.company_name})</span>
                              
                            </Typography>
                            {item.ordered_on !== "01/01/0001 (12:00 AM)" && (
                              <Typography
                                className={styles.scopedClassNameLine2}
                                sx={{
                                  paddingBottom: "5px",
                                  fontSize: "var(--content-font-size-16px)",
                                }}
                              >
                                <span className={styles.content}>{t("EquipmentsTab.orderedOn")} </span>
                                <span className={styles.scopedClassColorText2}>
                                  {item.ordered_on}
                                </span>
                              </Typography>
                            )}
                            <Box
                              display="flex"
                              flexDirection={{ xs: "column", sm: "row" }}
                              alignItems={{ sm: "flex-start" }}
                              justifyContent={{
                                xs: "flex-start",
                                sm: "space-between",
                              }}
                            >
                              <Typography
                                sx={{
                                  paddingBottom: "5px",
                                  fontSize: "var(--content-font-size-16px)",
                                }}
                                className={styles.scopedClassNameLine2}
                              >
                                  <OrderTypeText
                                        orderType={item.order_type}
                                        orderStatus={item.order_status}
                                        deliverOn={item.delivered_on}
                                        deliverBy={item.deliver_by}
                                        pickedUpOn={item.picked_up_on}
                                        pickupBy={item.pickup_by}
                                        serviceOn={item.serviced_on}
                                        serviceBy={item.service_by}
                                        mixOrderConfiguration={item?.mix_order_configuration}
                                      />
                              </Typography>
                              </Box>
                              <div className={styles.iconContainer}>
                              <Stack
                                direction="row"
                                spacing={0.5}
                                display="flex"
                                marginBottom="2px"
                                alignItems="center"
                                justifyContent="flex-start"
                                sx={{
                                  fontSize: "var(--common-content)",
                                }}
                              >
                                
                          <Stack direction="column" alignItems="center">
                            {PriorityIcon(PriorityNameEnum[item?.order_priority])}
                      </Stack>
                      {item?.reason_code && <Stack direction="column" alignItems="center">
                              {ReasonCode(item?.reason_code, item?.order_type)}
                              </Stack>} 
                                  <Stack direction="column" alignItems="center">
                                    <OrderType mixOrderConfiguration={item?.mix_order_configuration}/>
                                  </Stack>
                              </Stack>
                              </div>
                          </Box>
                        </Typography>
                          </RouterLink>
                      </CardContent>
                    </Card>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                fontSize: "var(--common-heading)",
                marginTop: "30px",
              }}
            >
              <CustomTablePagination
                totalRecord={orderData.length}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                isLoading={isPaginationLoading}
              />
            </div>    
          </div>
          
        ) : (
          <div style={{ marginTop: "50px" }}>
            <NoData
              message={
                isLoading ? <img alt="Loading..." src={loader} /> : showMessage
              }
            />
          </div>
        )}
      </div>
      {proofData && Object.keys(proofData).map((proofKey) => {
        if (proofData[proofKey]) {
          return (
            <OrdersProofDetail
              key={proofKey}
              proofData = {proofOfDelivery?.mix_order_configuration?.includes(",") ? proofOfDelivery[proofKey] : proofData[proofKey]}
              setProofData = {setProofData}
            />
          );
        }
        return null;
      })}
    </>
  );
};

export default PatientsOrder;
